/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import checkIcon from '~/assets/icons/offstorepay/check.svg';
import GreenCheckIcon from '~/assets/icons/offstorepay/green-check.svg';
import ProPlanModal from '../../../../components/Popups/ProPlan';
// import LoadingContainer from '../../../../components/LoadingContainer';
import Loader from '../../../../components/Loader'

import api from '../../../../services/api';
import dataFeeOffstorePay from '../../../../data/fee_offstorepay.json';

import Whatsapp from './ConfigurationWhatsapp';
import MercadoPago from './ConfigurationMercadoPago';
import PagBank from './ConfigurationPagBank';

import Plans from '../Plans';

import {
  Container,
  Body,
  DivText,
  DivCards,
  Card,
  NewLabel,
  LoaderContainer,
  LabelPro,
  DivDescriptionOffstorePay,
  DescriptionOffstorePay,
  FeesOffstorePay,
  DivZoop,
  // LabelPro,
  // LabelSoon,
  // DivMethod,
  // Radio,
  // DivCep,
  // SpanStatus,
} from './styles';
import { Modal } from '~/components/Modal';
import { Button } from '~/components/Button';
import ConfigurationOffstorePay from './ConfigurationOffstorePay';
import { AccountBalance } from '~/pages/Responsive/PaymentMethods/AccountBalance';
import ConfigurationAccountBalance from './AccountBallance';
import { useGetSellerInfo } from '~/hooks/use-get-seller-info';
import ConfigurationOffstorePayConfig from './ConfigurationOffstorePayComponents/Configuration';
import { OffstorePayModal } from './OffstorePayModal';
import { UniversalLoader } from '~/components/UniversalLoader/UniversalLoader';

function PaymentMethods({ setIndexMenu }) {
  const [checkWhatsapp, setCheckWhatsapp] = useState(false);
  const [checkOffstorePay, setCheckOffstorePay] = useState(false);
  const [checkMercadoPago, setCheckMercadoPago] = useState(false);
  const [checkPagBank, setCheckPagBank] = useState(false);

  const [profile, setProfile] = useState([]);
  const [pro, setPro] = useState();
  const [mercadoPago, setMercadoPago] = useState([]);
  const [showProPlanPopUp, setShowProPlanPopUp] = useState(false);
  const [showCardPro, setShowCardPro] = useState(false);
  const [hasTheme, setHasTheme] = useState(false)

  const [pagBank, setPagBank] = useState([]);

  const [showLoaderOffstopayButton, setShowLoaderOffstopayButton] = useState(false)

  const history = useHistory();

  const [initialLoading, setInitialLoading] = useState(true);

  const [page, setPage] = useState(0);

  const userId = useSelector(
    state => state.user && state.user.profile && state.user.profile.id
  );

  // const history = useHistory();
  // const dispatch = useDispatch();

  async function VerifyPaymentMethods(paymentMethod, value) {
    await api.put('/verifypaymentmethods', {
      paymentMethod,
      value
    }).then(async response => {
      console.log(response.data)
      if(response.data.error) {
        toast.error(response.data.error)

        return 0
      }
      setCheckWhatsapp(response.data.whatsapp);
      setCheckOffstorePay(response.data && response.data.offstorepay)
      setCheckMercadoPago(
        response.data && response.data.mercadopago
      );
      setCheckPagBank(
        response.data && response.data.pagbank
      );

      if(paymentMethod === 'whatsapp') {
        setProfile({...profile, has_whatsapp: response.data.whatsapp})
      }

      if(paymentMethod === 'mercadopago') {
        await api.get('/orderconfigurations').then(async res => {
          if (res && res.data) {
            await api.put('/orderconfigurations', { show_cpf_cnpj: true });
          }
        });
      }

      if(paymentMethod === 'offstorepay') {
        await handleChangeOffstorePay({
          userId,
          offstore_pay_enabled: value
        });
      }

    });
  }

  useEffect(() => {
    async function load() {
      if(userId) {
        await api.get(`/userthemeconfigurations/hastheme/${userId}`).then(response => {
          setHasTheme(response && response.data && response.data.hasTheme);
        });
      }
    }

    load()
  },[userId])

  useEffect(() => {
    async function load() {
      try {
        setInitialLoading(true);
        await api.get('/getuser').then(response => {
          setProfile(response.data);
          // setCheckWhatsapp(response.data.has_whatsapp);
        });

        await VerifyPaymentMethods()

        await api.get('/mercadopago').then(response => {
          setMercadoPago(response && response.data);
          // setCheckMercadoPago(
          //   response && response.data && response.data.active
          // );
        });

        await api.get('/pagbank').then(response => {
          setPagBank(response && response.data);
          setCheckPagBank(response && response.data && response.data.active);
        });

        await api.get('/proplan').then(async response => {
          setPro(response.data);

          if (response.data === 0) {
            await api
              .put('/mercadopago', {
                active: false,
              })
              .then(res => {
                setMercadoPago(res && res.data);
              });

            await api
              .put('/updateuser', {
                has_whatsapp: true,
              })
              .then(res => {
                setProfile(res.data);
              });
          }
        });



        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const codepb = urlParams.get('codepb');

        if (code) {
          setPage(2);
        }

        if (codepb) {
          setPage(6);
        }

        setInitialLoading(false);
      } catch (err) {
        setInitialLoading(false);
      }
    }

    load();
  }, []);

  // function verifyAllPaymentsDisabled(value, paymentForm) {
  //   if (paymentForm === 'whatsapp') {
  //     if (
  //       value === false &&
  //       (!mercadoPago || (mercadoPago && !mercadoPago.active)) &&
  //       (offStorePayStatus !== 'enabled' ||
  //         (offStorePayStatus === 'enabled' && !checkOffstorePay))
  //     ) {
  //       toast.error('Você precisa de pelo menos 1 forma de pagamento');

  //       return { error: true };
  //     }
  //   }

  //   if (paymentForm === 'mercadopago') {
  //     if (
  //       value === false &&
  //       !profile.has_whatsapp &&
  //       (offStorePayStatus !== 'enabled' ||
  //         (offStorePayStatus === 'enabled' && !checkOffstorePay))
  //     ) {
  //       toast.error('Você precisa de pelo menos 1 forma de pagamento');

  //       return { error: true };
  //     }
  //   }

  //   if (paymentForm === 'offstorepay') {
  //     if (
  //       value === false &&
  //       !profile.has_whatsapp &&
  //       (!mercadoPago || (mercadoPago && !mercadoPago.active))
  //     ) {
  //       toast.error('Você precisa de pelo menos 1 forma de pagamento');

  //       return { error: true };
  //     }
  //   }

  //   return { ok: true };
  // }

  async function onChangeActiveWhatsapp(value) {
    // const verify = verifyAllPaymentsDisabled(value, 'whatsapp');

    // if (verify.ok) {
    //   setCheckWhatsapp(value);

    //   const newUser = await api.put('/updateuser', {
    //     whatsapp: profile.whatsapp,
    //     has_whatsapp: value,
    //   });

    //   setProfile(newUser.data);
    // }

    VerifyPaymentMethods('whatsapp', value)

    return 0;
  }

  // async function onChangeActiveOffstorePay(value) {
  //   if (
  //     value === false &&
  //     (!profile.has_whatsapp || (mercadoPago && !mercadoPago.active))
  //   ) {
  //     return toast.error('Você precisa de pelo menos 1 forma de pagamento');
  //   }


  //   setCheckOffstorePay(value);
  // }

  async function onChangeActiveMercadoPago(value) {
    // const verify = verifyAllPaymentsDisabled(value, 'mercadopago');

    // if (verify.ok) {
    //   setCheckMercadoPago(value);

    //   const newMercadoPago = await api.put('/mercadopago', {
    //     active: value,
    //   });

    //   setMercadoPago(newMercadoPago && newMercadoPago.data);

    //   await api.get('/orderconfigurations').then(async res => {
    //     if (res && res.data) {
    //       await api.put('/orderconfigurations', { show_cpf_cnpj: true });
    //     }
    //   });
    // }

    // return 0;

    VerifyPaymentMethods('mercadopago', value)
  }

  async function onChangeActivePagBank(value) {
    // await api.put('/pagbank', {
    //   active: value,
    // });

    // setCheckPagBank(value);
    VerifyPaymentMethods('pagbank', value)
  }

  function ProPlanPopUp(pro_modal, confirm) {
    setShowProPlanPopUp(pro_modal);

    if (confirm) {
      setShowCardPro(true);
    }
  }

  async function handleClickBackButton() {
    setPage(0);

    await api.get('/mercadopago').then(response => {
      setMercadoPago(response && response.data);
      setCheckMercadoPago(response && response.data && response.data.active);
    });
  }

  async function handleClickBackButtonPb() {
    setPage(0);

    await api.get('/pagbank').then(response => {
      setPagBank(response && response.data);
      setCheckPagBank(response && response.data && response.data.active);
    });
  }



  const {
    handleGetSellerInfo,
    handleChangeOffstorePay,
    handleDeleteSeller,
  } = useGetSellerInfo();

  // recommended, pending, ok, denied
  const [offStorePayStatus, setOffStorePayStatus] = useState('recommended');
  const [offStorePay, setOffStorePay] = useState()
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [openOffstorePayModal, setOpenOffstorePayModal] = useState(false);
  const handleCloseModal = () => setOpenOffstorePayModal(false);

  async function load(loginWithoutLoading) {
    if (loginWithoutLoading) setInitialLoading(true);
    try {
      setShowLoaderOffstopayButton(true)

      const response = await handleGetSellerInfo({
        userId,
      });

      setOffStorePayStatus(
        response.sellerInfo.blocked || response.sellerInfo.current_status === 'disabled'
          ? 'blocked'
          : response.sellerInfo.current_status
      );

      setOffStorePay(response.sellerInfo);

      // await api.get('/verifysellerispending').then(res => {
      //   if(res && res.data && res.data.updated) {
      //     setOffStorePayStatus(res.data.seller.current_status)
      //   }
      // })

      setModalIsOpen(response.sellerInfo.status === 'pending');
      setCheckOffstorePay(
        response.sellerInfo.offstore_pay_enabled ||
          response.sellerInfo.current_status === 'pending'
      );


    } catch (err) {
    } finally {
      setInitialLoading(false);
      setShowLoaderOffstopayButton(false)
    }
  }

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    const handleKeyPress = event => {
      if (event.key === 'Escape') {
        setOpenOffstorePayModal(false);
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      // Remove o event listener quando o componente for desmontado
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleChangeOffstorePayStatus = async status => {
    // const verify = verifyAllPaymentsDisabled(status, 'offstorepay');

    // if (verify.ok) {
    //   setCheckOffstorePay(status);
    //   const response = await handleChangeOffstorePay({
    //     userId,
    //   });

    //   if (response.status === 200) return load(false);
    // }
    VerifyPaymentMethods('offstorepay', status)

  };

  const handleTryAgainSeller = async () => {
    setInitialLoading(true);
    const response = await handleDeleteSeller({
      userId,
    });
    setInitialLoading(false);
    if (response?.status === 200)
      return history.push('/paymentmethods/configurationoffstorepay');
  };

  return (
    <>
      {modalIsOpen && (
        <Modal>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 24,
            }}
          >
            <img src={checkIcon} alt="check-icon" />
            <p
              style={{
                color: 'rgba(0, 0, 0, 1)',
                fontWeight: 600,
                fontSize: 24,
                textAlign: 'center',
              }}
            >
              Seus dados estão em análise!
            </p>
            <p
              style={{
                color: 'rgba(131, 131, 131, 1)',
                fontSize: 16,
                textAlign: 'center',
              }}
            >
              Aguarde alguns minutos e você já poderá receber pagamentos no site
            </p>
            <Button onClick={handleCloseModal}>
              <p>Entendi</p>
            </Button>
          </div>
        </Modal>
      )}
      {openOffstorePayModal && (
        <OffstorePayModal closeModal={handleCloseModal} />
      )}
      {(showCardPro && <Plans />) || (
        <Container>
          {page === 0 && (
            <>
              <UniversalLoader open={initialLoading} />
              {/* <LoadingContainer loading={initialLoading} /> */}
              <Body>
                <ProPlanModal
                  showModal={showProPlanPopUp}
                  Modal={ProPlanPopUp}
                  is_desktop
                />

                <DivText>
                  <strong>Selecione as formas de pagamento</strong>
                  <span>
                    Escolha como seus clientes poderão pagas pelas compras
                  </span>
                </DivText>

                <DivCards>
                  <Card>
                    <div className="left" onClick={() => setPage(1)}>
                      <strong>Whatsapp</strong>
                      <span>Pagamento combinado via Whatsapp</span>
                    </div>

                    <div className="right align-center">
                      <button type="button" onClick={() => setPage(1)}>
                        Editar
                      </button>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={checkWhatsapp}
                          onChange={e =>
                            onChangeActiveWhatsapp(e.target.checked)
                          }
                        />
                        <span className="slider round" />
                      </label>
                    </div>
                  </Card>

                  {hasTheme && offStorePay &&
                  <Card
                    switchColorActive={offStorePayStatus === 'pending' ? '#ffb525' : ''}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <div className="w-full">
                      <div className="offstore-pay-header">
                        <strong>Offstore Pay</strong>
                        {offStorePayStatus === 'pending' && (
                          <span className="pending-flag">Em análise</span>
                        )}
                        {/* {offStorePayStatus === 'recommended' && (
                          <span className="recommended-flag">Recomendado</span>
                        )} */}
                        {offStorePayStatus === 'active' ||
                          (offStorePayStatus === 'enabled' && checkOffstorePay && (
                            <span className="active-flag">Ativo</span>
                          ))}
                        {offStorePayStatus === 'denied' && (
                          <span className="denied-flag">Reprovado</span>
                        )}
                        {offStorePayStatus === 'blocked' && (
                          <span className="denied-flag">Bloqueado</span>
                        )}


                        {pro === 0 && (
                          <LabelPro>
                            <span>PRO</span>
                          </LabelPro>
                        )}
                      </div>

                      <span>Receba pagamentos no site via Cartão e PIX</span>

                      {!showLoaderOffstopayButton &&
                        offStorePayStatus === 'recommended' && (
                          <>
                            <DivDescriptionOffstorePay>
                              <FeesOffstorePay>
                                <span>Taxas por transação</span>

                                <div>
                                  <div>
                                    <strong>Cartão</strong>
                                    <span>{dataFeeOffstorePay.credit}</span>
                                  </div>

                                  <div>
                                    <strong>Pix</strong>
                                    <span>{dataFeeOffstorePay.pix}</span>
                                  </div>

                                  <div>
                                    <strong>Boleto</strong>
                                    <span>{dataFeeOffstorePay.bolet}</span>
                                  </div>
                                </div>
                              </FeesOffstorePay>

                              <span className="green">
                                Não cobramos taxas por venda no Offstore Pay
                              </span>
                            </DivDescriptionOffstorePay>

                            <button
                              className="offstore-pay-activate-button"
                              type="button"
                              onClick={
                                pro >= 1
                                  ? () =>
                                  setOpenOffstorePayModal(true)
                                  : () => setShowProPlanPopUp(true)
                              }
                            >
                              Ativar
                            </button>
                          </>
                        )}
                    </div>

                    {(offStorePayStatus === 'active' ||
                      offStorePayStatus === 'enabled') && (
                      <div
                        className="right full-right"
                        style={{
                          height: '100%',
                          alignSelf: 'top',
                        }}
                      >
                        <button
                          type="button"
                          onClick={() =>
                            setPage(3)
                          }
                        >
                          Editar
                        </button>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={checkOffstorePay}
                            onChange={e =>
                              handleChangeOffstorePayStatus(e.target.checked)
                            }
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    )}
                  </div>

                  {showLoaderOffstopayButton && (
                    <LoaderContainer>
                      <Loader size={25} />
                    </LoaderContainer>
                  )}

                  {!showLoaderOffstopayButton && (
                    <>
                      {offStorePayStatus === 'pending' && (
                        <div
                          style={{
                            backgroundColor: '#FFB5250F',
                            height: 40,
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#FFB525',
                            fontWeight: 500,
                            fontSize: 13,
                            borderRadius: 6,
                          }}
                        >
                          <p>Dados em análise, aguarde alguns minutos</p>
                        </div>
                      )}

                      {offStorePayStatus === 'blocked' && (
                        <div
                          style={{
                            backgroundColor: '#FF303C0F',
                            padding: '16px',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            color: '#FF303C',
                            fontWeight: 500,
                            fontSize: 13,
                            borderRadius: 6,
                            marginTop: '12px',
                          }}
                        >
                          <p>
                            Conta bloqueada devido ao alto número de contestações.
                          </p>

                          <p style={{ marginTop: '12px' }}>
                            A Zoop, empresa que processa os pagamentos do Offstore
                            Pay bloqueou sua conta por representar risco, devido ao
                            alto número de contestações recebidas.
                          </p>

                          <p style={{ marginTop: '12px' }}>
                            Entre em contato com o suporte.
                          </p>
                        </div>
                      )}

                      {(offStorePayStatus === 'active' ||
                        offStorePayStatus === 'enabled' ||
                        offStorePayStatus === 'blocked') && (
                          <div
                            style={{
                              backgroundColor: '#EFEFEF',
                              height: 40,
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              color: '#101010',
                              fontWeight: 500,
                              fontSize: 13,
                              borderRadius: 6,
                              cursor: 'pointer',
                              marginTop: '8px',
                            }}
                            onClick={() =>
                              setPage(4)
                            }
                          >
                            <p>Ver saldo da conta</p>
                          </div>
                        )}

                      {offStorePayStatus === 'denied' && (
                        <div
                          style={{
                            backgroundColor: '#0D85FF',
                            height: 40,
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#FFF',
                            fontWeight: 500,
                            fontSize: 13,
                            borderRadius: 6,
                            cursor: 'pointer',
                          }}
                          onClick={() => handleTryAgainSeller()}
                        >
                          <p>Tentar novamente</p>
                        </div>
                      )}
                    </>
                  )}

                  <DivZoop>
                    <span>Tecnologia</span>
                    <svg
                      width="44"
                      height="13"
                      viewBox="0 0 44 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M33.2652 11.1155C33.5957 9.71215 33.9213 8.329 34.2499 6.94657C34.5035 5.87968 34.7594 4.81332 35.0176 3.74754C35.0971 3.41919 35.2199 3.32519 35.5637 3.32172C35.947 3.31785 36.3304 3.31937 36.7137 3.32176C37.0966 3.32415 37.1828 3.40289 37.2458 3.79313C37.2508 3.8239 37.2671 3.85284 37.2839 3.89813C37.4695 3.86679 37.6006 3.74109 37.7494 3.66167C38.356 3.33783 39.0059 3.21177 39.6841 3.13973C40.422 3.06136 41.1465 3.0936 41.8635 3.25502C43.3276 3.58467 44.1544 4.73134 43.9858 6.28483C43.9083 6.99856 43.7514 7.69695 43.4875 8.36566C42.902 9.84916 41.7913 10.702 40.2425 10.9805C39.2963 11.1507 38.3401 11.1598 37.3877 10.9909C36.8835 10.9015 36.4567 10.6472 36.0131 10.338C35.8887 10.6432 35.8156 10.9439 35.7481 11.2461C35.6392 11.7329 35.5306 12.2199 35.4259 12.7076C35.3816 12.9141 35.2826 13.0058 35.0508 12.9997C34.4096 12.9828 33.7677 12.9952 33.1261 12.9938C33.0364 12.9936 32.9418 13.0123 32.8398 12.938C32.9791 12.3432 33.1204 11.7396 33.2652 11.1155ZM40.5506 5.37926C40.0048 5.28722 39.4572 5.31285 38.91 5.35974C38.4447 5.39961 38.0173 5.5594 37.6299 5.81316C37.2844 6.03938 36.9768 6.32015 36.8396 6.71877C36.7184 7.07062 36.6538 7.44207 36.5675 7.80564C36.5584 7.84369 36.5682 7.88744 36.5745 7.92772C36.6558 8.44372 37.07 8.79301 37.6282 8.8606C38.177 8.92705 38.7236 8.93253 39.2677 8.85126C39.7412 8.78052 40.1783 8.60221 40.5681 8.31694C41.1218 7.91175 41.3124 7.3166 41.4193 6.68118C41.5406 5.95987 41.2949 5.58196 40.5506 5.37926Z"
                        fill="#B6B6B6"
                      />
                      <path
                        d="M12.1258 8.71784C12.0162 8.22787 12.1484 7.772 12.222 7.31699C12.2974 6.85112 12.4212 6.39631 12.5992 5.95178C12.9823 4.99502 13.6336 4.29778 14.5265 3.80588C15.4477 3.29844 16.4497 3.13246 17.4821 3.10388C18.2666 3.08216 19.0526 3.12412 19.8008 3.37122C20.7612 3.68842 21.4489 4.30637 21.6705 5.33444C21.8143 6.00156 21.6758 6.65827 21.5223 7.30767C21.2888 8.2956 20.9146 9.20962 20.1314 9.90441C19.4901 10.4734 18.7319 10.8032 17.8986 10.9492C16.6852 11.1619 15.4622 11.1987 14.2543 10.9334C13.3182 10.7279 12.5981 10.2089 12.254 9.27046C12.1918 9.10076 12.1714 8.91572 12.1258 8.71784ZM18.0808 8.5053C18.1806 8.43117 18.2849 8.36224 18.3795 8.28205C18.9072 7.83515 19.0488 7.2052 19.1538 6.57183C19.2734 5.85075 18.7497 5.41443 18.1672 5.35206C17.6555 5.29727 17.1387 5.30391 16.6241 5.36959C16.226 5.4204 15.8631 5.55396 15.5502 5.8006C14.9088 6.30639 14.7346 7.02025 14.6801 7.78902C14.6359 8.41366 15.1215 8.76322 15.5276 8.83629C16.3918 8.9918 17.2498 8.95935 18.0808 8.5053Z"
                        fill="#B6B6B6"
                      />
                      <path
                        d="M24.3369 4.68028C25.1647 3.81071 26.1831 3.37264 27.3375 3.19949C28.4107 3.03852 29.4783 3.04939 30.5309 3.31207C31.2812 3.49932 31.8997 3.89485 32.3155 4.57995C32.6361 5.10827 32.6907 5.68143 32.6378 6.26696C32.5687 7.03324 32.3832 7.78074 32.0795 8.48722C31.4928 9.85189 30.4053 10.6217 28.987 10.918C27.7304 11.1805 26.4554 11.1992 25.194 10.9342C24.4443 10.7767 23.8241 10.3892 23.3981 9.72443C23.2178 9.44306 23.0942 9.13543 23.0766 8.81464C22.9997 7.41372 23.281 6.09979 24.1107 4.93724C24.1733 4.8496 24.2532 4.77437 24.3369 4.68028ZM29.5434 5.47754C28.8876 5.23855 28.2147 5.30278 27.5451 5.37833C26.639 5.48055 26.0578 5.99074 25.782 6.85119C25.7163 7.05607 25.6767 7.27006 25.6355 7.48188C25.4888 8.23643 25.8618 8.74389 26.6195 8.86664C26.9527 8.92062 27.2845 8.91784 27.615 8.90158C28.2872 8.8685 28.9099 8.68572 29.405 8.19488C29.8223 7.78107 29.953 7.24209 30.0743 6.69241C30.1905 6.16556 30.0111 5.78224 29.5434 5.47754Z"
                        fill="#B6B6B6"
                      />
                      <path
                        d="M9.32857 4.77921C8.48339 5.49608 7.64976 6.19929 6.82335 6.91088C6.46504 7.2194 6.264 7.22291 5.92137 6.89218C5.62164 6.60287 5.32215 6.31331 5.02056 6.02596C4.6789 5.70042 4.70587 5.41834 5.07647 5.11295C6.34911 4.06426 7.6147 3.007 8.88212 1.95199C8.91878 1.92147 8.94594 1.87953 8.98261 1.83703C8.89271 1.69275 8.75593 1.73732 8.64194 1.73712C6.75026 1.73387 4.85857 1.73273 2.9669 1.73645C2.76906 1.73683 2.60618 1.69869 2.4969 1.51911C2.43773 1.42188 2.41053 1.32572 2.43922 1.21097C2.52386 0.872438 2.60674 0.533353 2.68209 0.192668C2.71221 0.0564928 2.7769 -0.0114119 2.92096 0.00156902C2.99534 0.00827113 3.07085 0.00220328 3.14585 0.00220023C6.94589 0.00204416 10.7459 0.00191975 14.546 0.00179265C14.6361 0.0017896 14.7262 0.00179227 14.8163 0.00179227C14.828 0.0243944 14.8397 0.0469961 14.8514 0.0695981C13.0153 1.63605 11.1792 3.2025 9.32857 4.77921Z"
                        fill="#B6B6B6"
                      />
                      <path
                        d="M6.47104 10.9239C4.53018 10.9236 2.61434 10.9235 0.698492 10.9222C0.598905 10.9222 0.497545 10.9225 0.400073 10.9053C0.104424 10.8532 -0.0507656 10.6387 0.0149304 10.3446C0.127276 9.8418 0.224806 9.33516 0.379033 8.84211C0.480323 8.5183 0.553091 8.4572 0.893873 8.4571C2.96798 8.45652 5.0421 8.45691 7.11621 8.457C8.19908 8.45704 9.28195 8.45629 10.3648 8.45759C10.7976 8.45811 10.9619 8.65252 10.8709 9.07096C10.763 9.56632 10.6478 10.0599 10.5092 10.5483C10.4447 10.7754 10.3161 10.8884 10.0937 10.9106C9.98623 10.9213 9.87769 10.9252 9.76965 10.9252C8.67845 10.9256 7.58725 10.9246 6.47104 10.9239Z"
                        fill="#B6B6B6"
                      />
                    </svg>
                  </DivZoop>
                </Card>
                  }


                  <Card>
                    <div
                      className="left"
                      onClick={
                        pro >= 1
                          ? () => setPage(2)
                          : () => setShowProPlanPopUp(true)
                      }
                    >
                      <div>
                        <strong>Mercado Pago</strong>

                        {pro === 0 && (
                          <LabelPro>
                            <span>PRO</span>
                          </LabelPro>
                        )}
                        {/* <NewLabel>Novo</NewLabel> */}
                      </div>

                      <span>Receba pagamentos no site via Cartão e PIX</span>
                    </div>

                    {mercadoPago && mercadoPago.access_token ? (
                      <div className="right align-center">
                        <button
                          type="button"
                          onClick={
                            pro >= 1
                              ? () => setPage(2)
                              : () => setShowProPlanPopUp(true)
                          }
                        >
                          Editar
                        </button>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={checkMercadoPago}
                            onChange={
                              pro >= 1
                                ? e =>
                                    onChangeActiveMercadoPago(e.target.checked)
                                : () => setShowProPlanPopUp(true)
                            }
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    ) : (
                      <button
                        type="button"
                        onClick={
                          pro >= 1
                            ? () => setPage(2)
                            : () => setShowProPlanPopUp(true)
                        }
                      >
                        Ativar
                      </button>
                    )}
                  </Card>

                  <Card>
                    <div
                      className="left"
                      onClick={
                        pro >= 1
                          ? () => setPage(6)
                          : () => setShowProPlanPopUp(true)
                      }
                    >
                      <div>
                        <strong>PagBank</strong>

                        {pro === 0 && (
                          <LabelPro>
                            <span>PRO</span>
                          </LabelPro>
                        )}
                      </div>

                      <span>Receba pagamentos no site via Cartão e PIX</span>
                    </div>

                    {pagBank && pagBank.access_token ? (
                      <div className="right">
                        <button
                          type="button"
                          onClick={
                            pro >= 1
                              ? () => setPage(6)
                              : () => setShowProPlanPopUp(true)
                          }
                        >
                          Editar
                        </button>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={checkPagBank}
                            onChange={
                              pro >= 1
                                ? e => onChangeActivePagBank(e.target.checked)
                                : () => setShowProPlanPopUp(true)
                            }
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    ) : (
                      <button
                        type="button"
                        onClick={
                          pro >= 1
                            ? () => setPage(6)
                            : () => setShowProPlanPopUp(true)
                        }
                      >
                        Ativar
                      </button>
                    )}
                  </Card>
                </DivCards>
              </Body>
            </>
          )}

          {page === 1 && (
            <Whatsapp handleClickBackButton={handleClickBackButton} />
          )}
          {page === 2 && (
            <MercadoPago handleClickBackButton={handleClickBackButton} setIndexMenu={setIndexMenu} />
          )}
          {page === 3 && (
            <ConfigurationOffstorePay
              handleClickBackButton={handleClickBackButton}
            />
          )}
          {page === 4 && (
            <ConfigurationAccountBalance
              handleClickBackButton={handleClickBackButton}
            />
          )}

          {page === 5 && (
            <ConfigurationOffstorePayConfig
              handleClickBackButton={handleClickBackButton}
            />
          )}

          {page === 6 && (
            <PagBank
              handleClickBackButton={handleClickBackButtonPb}
              setIndexMenu={setIndexMenu}
            />
          )}
        </Container>
      )}
    </>
  );
}

export default PaymentMethods;
